import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react'

interface PortalState {
	show: boolean
	setShow: Dispatch<SetStateAction<boolean>>
}

const PortalContext = createContext({} as PortalState)

/**
 * PortalContext
 *
 * @param {mixed} children
 *
 *
 */

const PortalProvider = ({ children }) => {
	const [show, setShow] = useState(false)

	return (
		<PortalContext.Provider value={{ show, setShow }}>
			{children}
		</PortalContext.Provider>
	)
}

/***
 *
 * usePortalState
 */

const usePortalState = () => {
	const context = useContext(PortalContext)

	if (context === undefined)
		throw new Error(`usePortalState must be used within a PortalProvider`)

	return context
}

export { PortalProvider, usePortalState }
