import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useState,
} from 'react'

interface ShareState {
	show: {
		display: boolean
		position: number
	}
	setShow: Dispatch<SetStateAction<{ display: boolean; position: number }>>
}

const ShareContext = createContext({} as ShareState)

/**
 * ShareContext
 *
 * @param {mixed} children
 *
 *
 */

interface IShareProvider {
	children?: ReactNode
}

const ShareProvider = ({ children }: IShareProvider) => {
	const [show, setShow] = useState({
		display: false,
		position: 24,
	})

	return (
		<ShareContext.Provider value={{ show, setShow }}>
			{children}
		</ShareContext.Provider>
	)
}

/***
 *
 * useShareState
 */

const useShareState = () => {
	const context = useContext(ShareContext)

	if (context === undefined)
		throw new Error(`useShareState must be used within a ShareProvider`)

	return context
}

export { ShareProvider, useShareState }
