import { ReactNode } from 'react'
import { LightboxProvider, useLightboxState } from './LightboxProvider'
import { MenuProvider, useMenuState } from './MenuProvider'
import { ShareProvider, useShareState } from './NewsShareProvider'
import { PortalProvider, usePortalState } from './PortalProvider'
import { FilterProvider, useFilterState } from './NewsProvider'

interface ContextProps {
	children: ReactNode
}

const ContextProvider = ({ children }: ContextProps) => {
	return (
		<PortalProvider>
			<MenuProvider>
				<LightboxProvider>
					<ShareProvider>
						<FilterProvider>{children}</FilterProvider>
					</ShareProvider>
				</LightboxProvider>
			</MenuProvider>
		</PortalProvider>
	)
}

export {
	ContextProvider,
	useLightboxState,
	useMenuState,
	usePortalState,
	useShareState,
	useFilterState
}
