import { createContext, useState, useContext, ReactNode } from 'react'

const LightboxContent = createContext()

/**
 * LightboxProvider
 *
 * @param {mixed} children
 */

const LightboxProvider = ({ children }) => {
	const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
	const [activeLightboxImage, setActiveLightboxImage] = useState<number>(0)
	const [lightboxImages, setLightboxImages] = useState([])
	const [lightboxVideo, setLightboxVideo] = useState<boolean>(false)

	return (
		<LightboxContent.Provider
			value={{
				lightboxOpen,
				setLightboxOpen,
				activeLightboxImage,
				setActiveLightboxImage,
				lightboxImages,
				setLightboxImages,
				lightboxVideo,
				setLightboxVideo,
			}}
		>
			{children}
		</LightboxContent.Provider>
	)
}

const useLightboxState = () => {
	const context = useContext(LightboxContent)

	if (context === undefined)
		throw new Error(`useLightboxState must be used within a LightboxProvider`)

	return context
}

export { LightboxProvider, useLightboxState }
