import { GatsbyBrowser } from 'gatsby'
import { Layout } from './src/hoc/Layout'
import React from 'react'
import { ContextProvider } from './src/context'

import './src/styles/global.scss'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
	element,
}) => <ContextProvider>{element}</ContextProvider>

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
	if (typeof document !== `undefined` && !location.hash) {
		return document?.querySelector(`body`)?.scrollTo(0, 0)
	}
}
