import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useState,
} from 'react'

interface IFiltersProps {
	keywords: string
	categories: string[]
}

interface INewsContextProps {
	filters: IFiltersProps
	setFilters: Dispatch<
		SetStateAction<{ keywords: string; categories: string[] | [] }>
	>
}

const FilterContext = createContext({} as unknown as INewsContextProps)

/**
 * FilterProvider
 *
 * @param {mixed} children
 */

const FilterProvider = ({ children }) => {
	const [filters, setFilters] = useState({
		keywords: ``,
		categories: [],
	})

	return (
		<FilterContext.Provider value={{ filters, setFilters }}>
			{children}
		</FilterContext.Provider>
	)
}

const useFilterState = () => {
	const context = useContext(FilterContext)

	if (context === undefined)
		throw new Error(`useFilterState must be used within a FilterProvider`)

	return context
}

export { FilterProvider, useFilterState }
